.modal-dialog {
  background-color: white;
  border-radius: 10px;
  position: relative;
  pointer-events: all;
  top: 10%;
  transform: translate(0, -10%);
  max-height: 60vh;
  min-height: 20vh;

  > .modal-header {
    padding: 10px 20px;
    font-size: 2.5rem;
    background: transparent;
    position: fixed;
    z-index: 1001;
    top:0;
    left:0;
    right:0;


    .modal-title {
      line-height: 1.5;
    }

    > button {
      font-size: 1.4em;
    }
  }

  > .modal-body {
    padding: 0px;
  }
}
.mailchimp {
  > input {
    margin-bottom: 10px !important;
  }
  > button {
    padding: 0 28px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    border-radius: 8px;
    background-color: transparent;
    background: linear-gradient(95deg, var(--color-primary) 15%, var(--color-tertiary) 45%, var(--color-pink) 75%, var(--color-secondary) 100%) 95%/200% 100%;
    transition-duration: 300ms;
    color: var(--color-white);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    text-decoration: none;
    text-shadow: var(--shadow-light);
    display: inline-block;
    height: 50px;
    line-height: 47px;
    letter-spacing: 0.5px;
    width: auto;

  }
  }
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #00000059;
  z-index: 1000;
}
