/*---------------------
    Tab Styles  
-----------------------*/

.rn-default-tab {
    // Tab Button 
    .tab-button {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;
        cursor: pointer;
        .react-tabs__tab {
            padding: 20px 44px;
            margin: 0;

            @media #{$lg-layout} {
                padding: 20px 24px;
            }

            @media #{$md-layout} {
                padding: 20px 24px;
            }

            @media #{$sm-layout} {
                padding: 20px 24px;
            }

            &.react-tabs__tab--selected {
                background: var(--color-lessdark);
            }
        }

        .rn-tab-button {
            display: flex;
            height: 100%;
            button {
                padding: 0;
                margin: 0;
                background: transparent;
                border: 0 none;
                color: var(--color-white);
            }
            .content {
                padding-right: 24px;
                padding-left: 24px;
                .description {
                    font-size: 15px;
                    line-height: 18px;
                 }
                 .title {
                     padding-bottom: 4px;
                     margin: 0;
                     font-size: 18px;
                    line-height: 20px;
                    font-weight: bold;
                 }

            }
            
            .icon {
                display: flex;
                width: 60px;
                border-radius: 5px;
                background: var(--color-primary-darker);
                color: var(--color-primary);
                text-align: center;
                font-size: 20px;
                justify-content: center;
                align-items: center;
                min-height: 100%;
                min-width: 60px;
            }
        }
    }

    // Tab Content 
    .rn-tab-content {
        background: var(--color-lessdark);
        padding: 40px 40px;
        @media #{$sm-layout} {
            padding: 30px;
        }
        p {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &.style-two {
        .tab-button {
            margin-bottom: 30px;
            .react-tabs__tab {
                border-radius: var(--radius-small);
            }
        }
        .rn-tab-content {
            border-radius: var(--radius-small);
        }
    }
    .tab-button-top{
        padding: 10px;
        .description {
            font-size: 15px;
            line-height: 18px;
        }
    }
}